import React, { useState } from "react";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import app from "./firebase";
import './ImageUploader.css';

const ImageUploader = ({ onImageUpload, onAllImagesUpload }) => {
  const [uploading, setUploading] = useState(false);
  const [imageURLs, setImageURLs] = useState([]);
  const [allImageURLs, setAllImageURLs] = useState([]); // New state for all URLs

  async function handleImageChange(e) {
    const images = Array.from(e.target.files);
    if (images.length > 0) {
      setUploading(true);
      const storage = getStorage(app);
      const uploadPromises = images.map((image) => {
        const storageRef = ref(storage, "images/" + image.name);
        return uploadBytes(storageRef, image)
          .then(() => getDownloadURL(storageRef))
          .catch((error) => {
            console.error('Error uploading image: ', error);
            return null; // Ensure a URL is returned to maintain array length
          });
      });

      try {
        const urls = await Promise.all(uploadPromises);
        const filteredUrls = urls.filter(url => url !== null); // Filter out failed uploads
        setImageURLs((prevUrls) => [...prevUrls, ...filteredUrls]);
        setAllImageURLs((prevUrls) => {
          const updatedUrls = [...prevUrls, ...filteredUrls];
          onAllImagesUpload(updatedUrls); // Pass updated URLs to parent
          return updatedUrls;
        });

        // Call the parent component's function to pass the download URLs
        onImageUpload(filteredUrls);
      } catch (error) {
        console.log(error);
      } finally {
        setUploading(false);
      }
    }
  }

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>Upload Images</h3>
      <input type="file" multiple onChange={handleImageChange} className="input"/>
      <button className="transparent-button"disabled={uploading}>
        {uploading ? "Uploading" : "Upload Images"}
      </button>
      <div>
        {imageURLs.map((url, index) => (
          <img key={index} src={url} style={{ maxWidth: 150, margin: 10 }} alt={`uploaded ${index}`} />
        ))}
      </div>
    
    </div>
  );
};

export default ImageUploader;
