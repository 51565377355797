import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "./firebase";
import { toast } from "react-toastify";
import SignInwithGoogle from "./signInWIthGoogle";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success("User logged in successfully", {
        position: "top-center",
      });
      if (email === "admin.limra@gmail.com" && password === "admin12345") {
        window.location.href = "/limraprofile";
      }
       else if (email === "admin.rashid@gmail.com" && password === "admin67890") {
        window.location.href = "/rashidprofile";
      }
       else {
        window.location.href = "/ImageUpload";
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message, {
        position: "bottom-center",
      });
    }
  };

  return (
    <div>
      <form className="login" onSubmit={handleSubmit}>
        <h3 className="text-2xl font-semibold">Login</h3>
        <div className="flex flex-col gap-2">
          <label className="text-sm text-lama-700">Email address</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm text-lama-700">Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="d-grid mt-10">
          <button className="bg-blue text-black p-2 rounded-md" type="submit">
            Submit
          </button>
        </div>
        {/* <p className="forgot-password text-right">
          New user <a href="/register">Register Here</a>
        </p> */}
      </form>
    </div>
  );
}

export default Login;
