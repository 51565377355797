// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Import Firebase Storage

const firebaseConfig = {
  apiKey: "AIzaSyDMvLUg-D2RVoCq6Ggx0n8Z6nV0kSWmEPY",
  authDomain: "geotagged-app-ead75.firebaseapp.com",
  projectId: "geotagged-app-ead75",
  storageBucket: "geotagged-app-ead75.appspot.com",
  messagingSenderId: "17706336958",
  appId: "1:17706336958:web:87c6e59acd6289a2b754ef"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage


export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app); // Initialize storage
export default app;
