import React, { useEffect, useState } from "react";
import { auth, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import './CurrentUser.css'

const CurrentUser = ({ onUserDetailsFetched }) => {
  const [userDetails, setUserDetails] = useState(null);

  const fetchUserData = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log(user);

        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setUserDetails(userData);
          console.log(userData);
          onUserDetailsFetched(userData); // Pass user details to parent component
        } else {
          console.log("No such document!");
        }
      } else {
        console.log("User is not logged in");
      }
    });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div>
      {userDetails ? (
        <>
          
          <div className="center">
          <h3>Welcome {userDetails.firstName}</h3>
            <p>Email: {userDetails.email}</p>
            <p>Name: {userDetails.firstName}</p>
            <p className="state">State - West Bengal </p>
            <p className="state">District - Cooch Behar </p>
            
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default CurrentUser;
