import React, { useState, useEffect } from 'react';
import Options from './option';
import ImageUploader from './captureImage';
import { auth, db } from "./firebase";
import CurrentUser from './currentUser';
import { setDoc, doc, collection } from "firebase/firestore";
import './ImageUpload.css';

const ImageUpload = ({ userDetails }) => {
  const [optionsData, setOptionsData] = useState({
    block: '',
    gp: '',
    phoneNumber: '',
    landmark: '',
    landmarkData: '',
    companyName: '',
    remarks: '',
  });
  const [imageData, setImageData] = useState(null);
  const [imageURLs, setImageURLs] = useState([]);
  const [currentUserDetails, setCurrentUserDetails] = useState(null);
  const [location, setLocation] = useState({ lat: null, lon: null });
  const [surveyStartTime, setSurveyStartTime] = useState(null);
  const [surveyEndTime, setSurveyEndTime] = useState(null);
  const [notification, setNotification] = useState('');
  const [isSurveyStopped, setIsSurveyStopped] = useState(false);

  const handleOptionChange = (data) => {
    setOptionsData(data);
    // Clear notification if all required fields are filled
    const { block, gp } = data;
    if (block && gp) {
      setNotification(''); // Clear the notification message
    }
  };

  const handleUserDetails = (details) => {
    setCurrentUserDetails(details);
  };

  const handleSurveyStart = (time) => {
    setSurveyStartTime(time);
  };

  const handleSurveyEnd = () => {
    const { block, gp } = optionsData;

    if (!block || !gp) {
      setNotification('Please fill all required fields (Block, G.P.)');
      return;
    }

    setSurveyEndTime(new Date().toLocaleTimeString());
    setIsSurveyStopped(true);
    setNotification('Survey completed.');
  };

  const handleImageUpload = (url) => {
    setImageData(url);
  };

  const handleAllImagesUpload = (urls) => {
    setImageURLs(urls);
  };

  const handleUploadMergedData = async () => {
    if (!isSurveyStopped) {
      setNotification('You need to stop the survey before uploading data.');
      return;
    }

    const { block, gp } = optionsData;
    if (!block || !gp) {
      setNotification('Please fill all required fields (Block, G.P.)');
      return;
    }

    if (!currentUserDetails || !currentUserDetails.firstName || !currentUserDetails.lastName || !currentUserDetails.email) {
      setNotification('User details are missing. Please log out and log in again.');
      return;
    }

    const mergedData = {
      ...optionsData,
      imageURLs,
      location,
      surveyStartTime,
      surveyEndTime,
      userDetails: currentUserDetails,
      date: new Date().toISOString().split('T')[0], // Adding the current date
    };

    try {
      const newDocRef = doc(collection(db, 'MergedData')); // Generate a new document reference
      await setDoc(newDocRef, { mergedData });
      console.log('Merged data saved to Firestore:', mergedData);
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error('Error saving data to Firestore:', error);
      setNotification('Error uploading data. Please try again.');
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLocation({
        lat: position.coords.latitude,
        lon: position.coords.longitude,
      });
    });
  }, []);

  return (
    <div className="container">
      <div className="flex-grow">
        <CurrentUser onUserDetailsFetched={handleUserDetails} />
        <div className="p-4">
          <Options onOptionChange={handleOptionChange} onSurveyStart={handleSurveyStart} />
        </div>
        <div className="card">
          <ImageUploader onImageUpload={handleImageUpload} onAllImagesUpload={handleAllImagesUpload} />
        </div>
      </div>
      <div className="space">
        <button className="btn btn-primary" onClick={handleSurveyEnd}>Complete Survey</button>
        <button className="btn btn-primary" onClick={handleUploadMergedData}>Submit</button>
        <button className="btn btn-danger" onClick={() => auth.signOut()}>Logout</button>
      </div>
      {notification && <div className="notification">{notification}</div>}
    </div>
  );
};

export default ImageUpload;
