import React, { useEffect, useState } from "react";
import { auth, db } from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import { useTable } from "react-table";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import './profile.css'; // Ensure to import the CSS file here

const storage = getStorage();

function Rashidprofile() {
  const [mergedData, setMergedData] = useState([]);

  const fetchImageAsBase64 = async (url) => {
    console.log('Fetching image URL:', url); // Log the URL being fetched
    try {
      const response = await fetch(url);
      if (!response.ok) {
        console.error('Network response was not ok:', response.statusText);
        return 'null'; // Return 'null' or handle error as appropriate
      }
      const blob = await response.blob();
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          console.log('Base64 Image:', reader.result); // Log the base64 result
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          console.error('FileReader error:', error);
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error fetching image as base64:', error);
      return 'null'; // Ensure this is handled properly
    }
  };

  const fetchMergedData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "MergedData"));
      console.log('Query Snapshot:', querySnapshot); // Log query snapshot
  
      const data = [];
      let index = 1;
  
      const promises = querySnapshot.docs.map(async (doc) => {
        const docData = doc.data().mergedData;
        console.log('Document Data:', docData); // Log document data
  
        if (docData) {
          const location = docData.location || {};
          const userDetails = docData.userdetails || {};
          const imageUrls = docData.imageURLs || [];
  
          const firstName = docData.userDetails.firstName || "No First Name";
          const lastName = docData.userDetails.lastName || "No Last Name";
          const fullName = `${firstName} ${lastName}`;
          if (firstName === "Rasid") {
          const imagePromises = imageUrls.map(async (url) => {
            try {
              const imageRef = ref(storage, url);
              const imageUrl = await getDownloadURL(imageRef);
              return imageUrl;
            } catch (error) {
              console.error('Error fetching image:', error);
              return 'null';
            }
          });
  
          const fetchedImageUrls = await Promise.all(imagePromises);
  
          data.push({
            index: index++,
            name: fullName,
            phoneNumber: docData.phoneNumber || "null",
            date: docData.date || "null",
            surveyStartTime: docData.surveyStartTime || "null",
            surveyEndTime: docData.surveyEndTime || "null",
            state: "West Bengal",
            district: "Cooch Behar",
            block: docData.block || "null",
            gp: docData.gp || "null",
            location: `Lat: ${location.lat || "null"}, Lon: ${location.lon || "null"}`,
            imageUrls: imageUrls.length ? imageUrls : ["null"],
            images: fetchedImageUrls.length ? fetchedImageUrls : ["null"],
            companyName: docData.companyName || "null",
            landmark: docData.landmark || "null",
            landmarkData: docData.landmarkData || "null",
            remarks: docData.remarks || "null",
          });
        }
      }
      });
  
      await Promise.all(promises);
  
      data.sort((a, b) => a.index - b.index);
      console.log('Fetched Data:', data); // Log the fetched data
  
      setMergedData(data);
    } catch (error) {
      console.error("Error fetching merged data:", error);
    }
  };
  useEffect(() => {
    fetchMergedData();
  }, []);
  const handleDownloadAll = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('MergedData');
  
      // Define headers
      worksheet.columns = [
        { header: 'Index', key: 'index', width: 10 },
        { header: 'Name', key: 'name', width: 20 },
        { header: 'Phone Number', key: 'phoneNumber', width: 15 },
        { header: 'Date', key: 'date', width: 15 },
        { header: 'Survey Start Time', key: 'surveyStartTime', width: 20 },
        { header: 'Survey End Time', key: 'surveyEndTime', width: 20 },
        { header: 'State', key: 'state', width: 15 },
        { header: 'District', key: 'district', width: 15 },
        { header: 'Block', key: 'block', width: 15 },
        { header: 'G.P.', key: 'gp', width: 15 },
        { header: 'Location', key: 'location', width: 20 },
        { header: 'Image URLs', key: 'imageUrls', width: 30 },
        { header: 'Images', key: 'images', width: 20 },
        { header: 'Company Name', key: 'companyName', width: 20 },
        { header: 'Landmark', key: 'landmark', width: 20 },
        { header: 'Landmark Data', key: 'landmarkData', width: 20 },
        { header: 'Remarks', key: 'remarks', width: 20 }
      ];
  
      const addImageToSheet = async (worksheet, row, imageUrl, columnIndex) => {
        try {
          const response = await fetch(imageUrl);
          const buffer = await response.arrayBuffer();
          const imageId = workbook.addImage({
            buffer,
            extension: 'png',
          });
  
          worksheet.addImage(imageId, {
            tl: { col: columnIndex - 1, row: row - 1 },
            ext: { width: 80, height: 80 }, // Adjust width and height as needed
            editAs: 'oneCell',
          });
        } catch (error) {
          console.error('Error adding image to sheet:', error);
        }
      };
  
      // Set every row's height to 2.98 cm (~84.5 points)
      
  
      for (const [rowIndex, row] of mergedData.entries()) {
        worksheet.addRow({
          index: row.index,
          name: row.name,
          phoneNumber: row.phoneNumber,
          date: row.date,
          surveyStartTime: row.surveyStartTime,
          surveyEndTime: row.surveyEndTime,
          state: row.state,
          district: row.district,
          block: row.block,
          gp: row.gp,
          location: row.location,
          imageUrls: row.imageUrls.join(', '),
          images: '', // Leave the 'Images' column empty initially
          companyName: row.companyName,
          landmark: row.landmark,
          landmarkData: row.landmarkData,
          remarks: row.remarks
        });
  
        // Add images to the "Images" column in the sheet
        for (const imageUrl of row.images) {
          await addImageToSheet(worksheet, rowIndex + 2, imageUrl, 13); // Column 13 corresponds to 'Images'
        }
      }
      worksheet.eachRow((row) => {
        row.height = 85;
      });
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), 'all_documents.xlsx');
    } catch (error) {
      console.error('Error downloading all data:', error);
    }
  };
  
  
  

  const columns = React.useMemo(
    () => [
      { Header: 'Index', accessor: 'index' }, // Changed from 'ID' to 'Index'
      { Header: 'Name', accessor: 'name' },
      { Header: 'Phone Number', accessor: 'phoneNumber' },
      { Header: 'Date', accessor: 'date' },
      { Header: 'Survey Start Time', accessor: 'surveyStartTime' },
      { Header: 'Survey End Time', accessor: 'surveyEndTime' },
      { Header: 'State', accessor: 'state' },
      { Header: 'District', accessor: 'district' },
      { Header: 'Block', accessor: 'block' },
      { Header: 'G.P.', accessor: 'gp' },
      { Header: 'Location', accessor: 'location' },
      { Header: 'Image URLs', accessor: 'imageUrls' },
      { Header: 'Images', accessor: 'images' },
      { Header: 'Company Name', accessor: 'companyName' },
      { Header: 'Landmark', accessor: 'landmark' },
      { Header: 'Landmark Data', accessor: 'landmarkData' },
      { Header: 'Remarks', accessor: 'remarks' }
    ],
    []
  );

  const tableInstance = useTable({ columns, data: mergedData });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h1>Data</h1>
      </div>
      <div className="table-wrapper">
        <table {...getTableProps()} className="profile-table">
        <thead>
  {headerGroups.map(headerGroup => {
    const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps(); // Extract key separately
    return (
      <tr key={key} {...headerGroupProps}>
        {headerGroup.headers.map(column => {
          const { key, ...columnProps } = column.getHeaderProps(); // Extract key separately
          return (
            <th key={key} {...columnProps}>
              {column.render('Header')}
            </th>
          );
        })}
      </tr>
    );
  })}
</thead>

          <tbody {...getTableBodyProps()}>
  {rows.map(row => {
    prepareRow(row);
    const { key, ...rowProps } = row.getRowProps(); // Extract key separately
    return (
      <tr key={key} {...rowProps}>
        {row.cells.map(cell => (
          <td {...cell.getCellProps()}>
            {cell.column.id === 'imageUrls' ? (
              <div dangerouslySetInnerHTML={{ __html: cell.value.join(", ") }}></div>
            ) : cell.column.id === 'images' ? (
              cell.value.map((url, idx) => (
                <img key={idx} src={url} alt={`img-${idx}`} width="50" height="50" />
              ))
            ) : (
              cell.render('Cell')
            )}
          </td>
        ))}
      </tr>
    );
  })}
</tbody>

        </table>
      </div>
      <div className="btn-container">
        <button className="btn btn-primary" onClick={handleDownloadAll}>
          Download All
        </button>
        <button className="btn btn-secondary" onClick={() => auth.signOut()}>
          Logout
        </button>
      </div>
    </div>
  );
}

export default Rashidprofile;
