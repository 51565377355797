import React, { useState } from 'react';
import './option.css';

const Options = ({ onOptionChange, onSurveyStart }) => {
  const [selectedOptions, setSelectedOptions] = useState({
    block: '',
    gp: '',
  });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [landmark, setLandmark] = useState('');
  const [landmarkData, setLandmarkData] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [remarks, setRemarks] = useState('');

  const handleOptionChange = (event, field) => {
    const newOptions = { ...selectedOptions, [field]: event.target.value };
    setSelectedOptions(newOptions);
    onOptionChange({
      ...newOptions,
      phoneNumber,
      landmark,
      landmarkData: landmark === 'Yes' ? landmarkData : null,
      companyName,
      remarks: remarks || null,
    });
  };

  const handleFirstInputClick = () => {
    onSurveyStart(new Date().toLocaleTimeString());
  };

  const handleLandmarkChange = (e) => {
    const value = e.target.value;
    setLandmark(value);
    onOptionChange({
      ...selectedOptions,
      phoneNumber,
      landmark: value,
      landmarkData: value === 'Yes' ? landmarkData : null,
      companyName,
      remarks: remarks || null,
    });
  };

  const handleLandmarkDataChange = (e) => {
    const value = e.target.value;
    setLandmarkData(value);
    onOptionChange({
      ...selectedOptions,
      phoneNumber,
      landmark,
      landmarkData: value,
      companyName,
      remarks: remarks || null,
    });
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    onOptionChange({
      ...selectedOptions,
      phoneNumber: value,
      landmark,
      landmarkData: landmark === 'Yes' ? landmarkData : null,
      companyName,
      remarks: remarks || null,
    });
  };

  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    setCompanyName(value);
    onOptionChange({
      ...selectedOptions,
      phoneNumber,
      landmark,
      landmarkData: landmark === 'Yes' ? landmarkData : null,
      companyName: value,
      remarks: remarks || null,
    });
  };

  const handleRemarksChange = (e) => {
    const value = e.target.value;
    setRemarks(value);
    onOptionChange({
      ...selectedOptions,
      phoneNumber,
      landmark,
      landmarkData: landmark === 'Yes' ? landmarkData : null,
      companyName,
      remarks: value || null,
    });
  };

  return (
    <div className="formfield">
      <h2 className="text-xl font-semibold mb-4">Select Options</h2>
      <input 
        type="text" 
        placeholder="Phone Number" 
        value={phoneNumber} 
        onChange={handlePhoneNumberChange} 
        className="inputfield"
      />
      <select 
        onChange={(e) => handleOptionChange(e, 'block')} 
        onClick={handleFirstInputClick}
        className="inputfield"
      >
        <option value="">Select Block</option>
        <option>Cooch Behar-1</option>
<option>Cooch Behar-2</option>
<option>Dinhata-1</option>
<option>Dinhata-2</option>
<option>Haldibari</option>
<option>Mathabhanga-1</option>
<option>Mathabhanga-2</option>
<option>Mekhliganj</option>
<option>Sitai</option>
<option>Sitalkuchi</option>
<option>Tufanganj-2</option>
<option>Tufanganj-1</option>
      </select>
      <select 
        onChange={(e) => handleOptionChange(e, 'gp')} 
        className="inputfield"
      >
        <option value="">Select G.P.</option>
        <option>CHANDAMARI</option>
<option>CHILKIRHAT</option>
<option>DAWAGURI</option>
<option>DEWANHAT</option>
<option>FALIMARI</option>
<option>GHUGUMARI</option>
<option>GURIAHATI – I</option>
<option>GURIAHATI – II</option>
<option>HARIBHANGA</option>
<option>JIRANPUR</option>
<option>MOAMARI</option>
<option>PANISHALA</option>
<option>PATCHHARA</option>
<option>PUTIMARI FULESWARI</option>
<option>SUKTABARI</option>
<option>AMBARI</option>
<option>BANESWAR</option>
<option>BARARANGRAS</option>
<option>CHAKCHAKA</option>
<option>DHANDHINGGURI</option>
<option>GOPALPUR</option>
<option>KHAGRABARI</option>
<option>KHAPAIDANGA</option>
<option>MADHUPUR</option>
<option>MARICHBARI KHOLTA</option>
<option>PATLAKHAWA</option>
<option>PUNDIBARI</option>
<option>TAKAGACHH RAJARHAT</option>
<option>BARA ATIABARI – I</option>
<option>BARA ATIABARI – II</option>
<option>BARA SOULMARI</option>
<option>BHETAGURI – I</option>
<option>BHETAGURI – II</option>
<option>DINHATA VILLAGE – I</option>
<option>DINHATA VILLAGE – II</option>
<option>GITALDAHA – I</option>
<option>GITALDAHA – II</option>
<option>GOSANIMARI – I</option>
<option>GOSANIMARI – II</option>
<option>MATALHAT</option>
<option>OKRABARI</option>
<option>PETLA</option>
<option>PUTIMARI – I</option>
<option>PUTIMARI – II</option>
<option>BAMANHAT – I</option>
<option>BAMANHAT – II</option>
<option>BARA SAKDAL</option>
<option>BURIRHAT – I</option>
<option>BURIRHAT – II</option>
<option>CHOWDHURYHAT</option>
<option>GOBRACHHARA</option>
<option>KISMAT DASGRAM</option>
<option>NAZIRHAT – I</option>
<option>NAZIRHAT – II</option>
<option>SAHEBGANJ</option>
<option>SUKRURKUTHI</option>
<option>BAXIGANJ</option>
<option>DAXIN BARA HALDIBARI</option>
<option>DEWANGANJ</option>
<option>HEMKUMARI</option>
<option>PER MEKHLIGANJ</option>
<option>UTTAR BARA HALDIBARI</option>
<option>BAIRAGIRHAT</option>
<option>GOPALPUR</option>
<option>HAZRAHAT-I</option>
<option>HAZRAHAT-II</option>
<option>JORPATKI</option>
<option>KEDARHAT</option>
<option>KURSHAMARI</option>
<option>NAYARHAT</option>
<option>PACHAGARH</option>
<option>SIKARPUR</option>
<option>ANGERKATA PARADUBI</option>
<option>BARA SOULMARI</option>
<option>FULBARI</option>
<option>GHOKSHADANGA</option>
<option>LATAPATA</option>
<option>NISHIGANJ-I</option>
<option>NISHIGANJ-II</option>
<option>PREMERDANGA</option>
<option>RUIDANGA</option>
<option>UNISHBISHA</option>
<option>BAGDOKRA FULKADABRI</option>
<option>BHOTBARI</option>
<option>CHANGRABANDHA</option>
<option>JAMALDAHA</option>
<option>KUCHLIBARI</option>
<option>NIZTARAF</option>
<option>RANIRHAT</option>
<option>UCHALPUKURI</option>
<option>ADABARI GHAT</option>
<option>BRAHMATTAR CHHATRA</option>
<option>CHAMTA</option>
<option>SITAI – I</option>
<option>SITAI – II</option>
<option>BARA KOIMARI</option>
<option>BHAWERTHANA</option>
<option>CHHOTO SALBARI</option>
<option>GOLENAOHATI</option>
<option>GOSAIRHAT</option>
<option>KHALISAMARI</option>
<option>LALBAZAR</option>
<option>SITALKUCHI</option>
<option>ANDARAN FULBARI-I</option>
<option>ANDARAN FULBARI-II</option>
<option>BALABHUT</option>
<option>BALARAMPUR – I</option>
<option>BALARAMPUR – II</option>
<option>CHILAKHANA – I</option>
<option>CHILAKHANA – II</option>
<option>DEOCHARAI</option>
<option>DHALPAL – I</option>
<option>DHALPAL – II</option>
<option>MARUGANJ</option>
<option>NAKKATIGACHH</option>
<option>NATABARI – I</option>
<option>NATABARI – II</option>
<option>BAROKODALI – I</option>
<option>BAROKODALI – II</option>
<option>BHANUKUMARI – I</option>
<option>BHANUKUMARI – II</option>
<option>FALIMARI</option>
<option>MAHISKUCHI – I</option>
<option>MAHISKUCHI – II</option>
<option>RAMPUR – I</option>
<option>RAMPUR – II</option>
<option>SALBARI – I</option>
<option>SALBARI – II</option>
      </select>
     
      <select 
        onChange={handleLandmarkChange} 
        className="inputfield"
      >
        <option value="">Select Landmark</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      {landmark === 'Yes' && (
        <input 
          type="text" 
          placeholder="Landmark Data" 
          value={landmarkData} 
          onChange={handleLandmarkDataChange} 
          className="inputfield"
        />
      )}
      <input 
        type="text" 
        placeholder="Company Name" 
        value={companyName} 
        onChange={handleCompanyNameChange} 
        className="inputfield"
      />
      <input 
      type="text" 
      placeholder="Remarks" 
      value={remarks} 
      onChange={handleRemarksChange} 
      className="inputfield"
    />
    </div>
  );
};

export default Options;
